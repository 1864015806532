import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getNameValidation } from '../common/Validator';
import TextFormControl from '../common/TextFormControl';
import { SpinnerInfoBox } from 'rio-uikit';
import noop from 'lodash/fp/noop';

export const HEADLINE_ID = `intl-msg:settingsDialog.tab.personalData.headline`;
export const FIRST_NAME_LABEL_ID = `intl-msg:settingsDialog.tab.personalData.label.firstName`;
export const LAST_NAME_LABEL_ID = `intl-msg:settingsDialog.tab.personalData.label.lastName`;
export const FIRST_NAME_FIELD_ID = `intl-msg:settingsDialog.tab.personalData.firstNameInputField`;
export const LAST_NAME_FIELD_ID = `intl-msg:settingsDialog.tab.personalData.lastNameInputField`;
export const SAVE_BUTTON_LABEL_ID = `intl-msg:settingsDialog.tab.personalData.button.save.label`;

export default class PersonalDataTab extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }

    getInitialState(props) {
        const { firstName, lastName } = this.props;
        const nameValidator = getNameValidation();

        return {
            valid: {
                firstName: nameValidator(firstName) === null,
                lastName: nameValidator(lastName) === null,
            },
            value: {
                firstName: props.firstName || '',
                lastName: props.lastName || '',
            },
        };
    }

    renderMessage(id) {
        return (
            <FormattedMessage id={id} defaultMessage={id}/>
        );
    }

    dialogWillClose(nextProps) {
        return this.props.isDialogVisible && !nextProps.isDialogVisible;
    }

    syncFieldsFromProps(nextProps) {
        const nameValidator = getNameValidation();
        this.setFieldInState('firstName', nextProps.firstName, nameValidator(nextProps.firstName) === null);
        this.setFieldInState('lastName', nextProps.lastName, nameValidator(nextProps.lastName) === null);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.value.firstName || !this.state.value.lastName || this.dialogWillClose(nextProps)) {
            this.syncFieldsFromProps(nextProps);
        }
    }

    setFieldInState(field, value, valid) {
        const newState = { ...this.state };
        newState.valid[field] = valid;
        newState.value[field] = value;
        this.setState(newState);
    }

    getOnInputChange(field) {
        const nameValidator = getNameValidation();
        const onInputChange = event => {
            const value = event.target.value;
            const valid = nameValidator(value) === null;
            this.setFieldInState(field, value, valid);
        };
        return onInputChange.bind(this);
    }

    isOnConfirmDisabled() {
        return this.props.isPending || !this.isFormValid() || !this.isNameChanged();
    }

    isNameChanged() {
        return (this.props.firstName !== this.state.value.firstName ||
            this.props.lastName !== this.state.value.lastName);
    }

    isFormValid() {
        return this.state.valid.firstName && this.state.valid.lastName;
    }

    onConfirm() {
        if (!this.isOnConfirmDisabled()) {
            const user = {
                firstName: this.state.value.firstName,
                lastName: this.state.value.lastName,
                email: this.props.email,
                userGroupIds: [],
            };
            this.props.onSave(user);
        }
    }

    renderConfirmText() {
        const text = this.renderMessage(SAVE_BUTTON_LABEL_ID);
        return this.props.isPending ? <SpinnerInfoBox text={text} /> : text;
    }

    renderButtonToolbar() {
        const isDisabled = this.isOnConfirmDisabled() ? 'disabled' : '';
        return (
            <div className='btn-toolbar pull-right'>
                <div className={`btn btn-primary ${isDisabled}`} onClick={() => this.onConfirm()}>
                    {this.renderConfirmText()}
                </div>
            </div>
        );
    }

    renderHeadline() {
        return (
            <h4>{this.renderMessage(HEADLINE_ID)}</h4>
        );
    }

    render() {
        return (
            <div>
                {this.renderHeadline()}
                <TextFormControl
                    id={FIRST_NAME_FIELD_ID}
                    labelId={FIRST_NAME_LABEL_ID}
                    value={this.state.value.firstName}
                    validateInput={getNameValidation()}
                    onInputChange={this.getOnInputChange('firstName')}/>
                <TextFormControl
                    id={LAST_NAME_FIELD_ID}
                    labelId={LAST_NAME_LABEL_ID}
                    value={this.state.value.lastName}
                    validateInput={getNameValidation()}
                    onInputChange={this.getOnInputChange('lastName')}/>
                <br/>
                {this.renderButtonToolbar()}
            </div>
        );
    }
}

PersonalDataTab.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    isPending: PropTypes.bool,
    onSave: PropTypes.func,
    isDialogVisible: PropTypes.bool,
};

PersonalDataTab.defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    isPending: false,
    onSave: noop,
    isDialogVisible: false,
};
