import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/fp/pick';
import noop from 'lodash/fp/noop';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { showSettingsDialogAction } from '../actions/settingsDialogActions';
import { selectUserInfo } from '../selectors/settingsSelectors';
import { fetchUserInfoAction, fetchLanguageAction } from '../actions/settingsActions';

export const LANG_ID_TITLE = `intl-msg:popover.title`;
export const LANG_ID_LINK_PROFILE = `intl-msg:popover.link.profile`;
export const LANG_ID_LOGOUT = `intl-msg:popover.logout`;
export const LANG_ID_USER_INFO_UNKNOWN = `intl-msg:popover.userInfoUnknown`;

export class AccountPopoverContainer extends Component {

    constructor(props) {
        super(props);
        this.showUserSettingsDialog = this.showUserSettingsDialog.bind(this);
    }

    componentDidMount() {
        if (this.props.fetchUserInfo) {
            this.props.fetchUserInfo();
            this.props.fetchLanguage();
        }
    }

    getUserIdentityText() {
        const { firstName, lastName, email } = this.props.user;
        const fullName = (`${firstName} ${lastName}`).trim();

        return (!fullName && !email) ?
            (
                <div>
                    <FormattedMessage
                        id={LANG_ID_USER_INFO_UNKNOWN}
                        defaultMessage={'Recall of user information failed'} />
                </div>) :
            (
                <div>
                    <h4>{fullName}</h4>
                    <h6>{email}</h6>
                </div>
            );
    }

    showUserSettingsDialog(event) {
        event.preventDefault();
        this.props.showUserSettingsDialog(event);
    }

    renderTextLink(id, defaultMessage) {
        return (
            <a href='#' className='btn-block' onClick={this.showUserSettingsDialog}>
                <FormattedMessage id={id} defaultMessage={defaultMessage} />
            </a>
        );
    }

    renderAvatarPart() {
        return (
            <div className='Avatar text-center'>
                <span className='Avatar-icon rioglyph rioglyph-user' />
                {this.getUserIdentityText()}
            </div>
        );
    }

    renderLinkPart() {
        return (
            <div className='center-block text-center'>
                {this.renderTextLink(LANG_ID_LINK_PROFILE, LANG_ID_LINK_PROFILE)}
            </div>
        );
    }

    renderLogoutPart() {
        return (
            <div className='center-block cursor-pointer text-center'
                 onClick={this.props.onLogout}
                 role={'button'}
                 tabIndex={0}>
                <a className='btn-block'>
                    <span className='rioglyph rioglyph-off margin-right-5' />
                    <FormattedMessage id={LANG_ID_LOGOUT} defaultMessage='_Abmelden' />
                </a>
            </div>
        );
    }

    render() {
        return (
            <div className='accountMenu'>
                {this.renderAvatarPart()}
                <hr/>
                {this.renderLinkPart()}
                <hr/>
                {this.renderLogoutPart()}
            </div>
        );
    }

}

export function mapStateToProps(state, ownProps) {
    const whitelist = pick(['firstName', 'lastName', 'email']);
    const userInfo = selectUserInfo(state);

    return {
        user: whitelist(userInfo),
        backendUrl: ownProps.backendUrl,
    };
}

export function mapDispatchToProps(dispatch, ownProps) {
    return {
        onLogout: () => ownProps.onLogout(),
        showUserSettingsDialog: () => dispatch(showSettingsDialogAction()),
        fetchUserInfo: () => dispatch(fetchUserInfoAction(ownProps.backendUrl)),
        fetchLanguage: () => dispatch(fetchLanguageAction(ownProps.backendUrl)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPopoverContainer);

AccountPopoverContainer.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
    }),
    fetchUserInfo: PropTypes.func,
    showUserSettingsDialog: PropTypes.func,
    backendUrl: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
    fetchLanguage: PropTypes.func,
};

AccountPopoverContainer.defaultProps = {
    user: {
        firstName: '',
        lastName: '',
        email: '',
    },
    fetchUserInfo: noop,
    showUserSettingsDialog: noop,
    backendUrl: '',
    onLogout: noop,
    fetchLanguage: noop,
};
