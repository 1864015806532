/* eslint-env node */

import React from 'react';
import PropTypes from 'prop-types';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import cloneDeep from 'lodash/fp/cloneDeep';
import isEqual from 'lodash/fp/isEqual';

import { ActionBarItem } from 'rio-uikit';

import { EVENT_USER_LOGGED_OUT } from '../constants/api';

import AccountPopoverContainer from '../containers/AccountPopoverContainer';
import UserSettingsDialog from '../containers/SettingsDialogContainer';
import userSettingsReducer from '../reducers/reducer';

import { IntlProvider, FormattedMessage } from 'react-intl';
import { accessTokenStored, idTokenStored } from '../actions/settingsActions';
import { selectLanguage } from '../selectors/settingsSelectors';

import { runSagas } from '../sagas/rootSaga';
import { Provider, connect } from 'react-redux';

import '../style/css/rio-accountmenu.less';
import { DEFAULT_LOCALE } from '../lang';

// Initialize middlewares
const sagaMiddleware = createSagaMiddleware();
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = /*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // || */ compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(sagaMiddleware))(createStore);
const mountedStore = createStoreWithMiddleware(userSettingsReducer);

runSagas(sagaMiddleware);

const idTokenPropType = PropTypes.shape({
    /* eslint-disable camelcase */
    account: PropTypes.string,
    azp: PropTypes.string,
    email: PropTypes.string,
    family_name: PropTypes.string,
    given_name: PropTypes.string,
    locale: PropTypes.string,
    name: PropTypes.string,
    sub: PropTypes.string,
    /* eslint-enable camelcase */
});

function mapStateToProps(state, ownProps) {
    const lang = selectLanguage(state);
    return {
        defaultLocale: DEFAULT_LOCALE,
        key: lang.locale,
        locale: lang.locale,
        messages: lang.messages,
    };
}

const ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider);

const broadcastLogout = () => {
    document.dispatchEvent(new CustomEvent(EVENT_USER_LOGGED_OUT));
};

// FIXME: use the correct usersettings absolute URL
// TODO: implement React-style callback API for events with onLanguageChanged, onLoggedOut, onProfileChanged

export default class DefaultAccountMenu extends React.PureComponent {
    static get defaultProps() {
        return {
            idToken: null,
            userSettingsEndpoint: '/usersettings',
        };
    }

    static get propTypes() {
        // FIXME: Deprecate `accessToken` and `userSettingsEndpoint` as
        //        soon as all information is taken from the `idToken`
        //        and the user settings has its own SPA
        return {
            accessToken: PropTypes.string.isRequired,
            idToken: idTokenPropType,
            userSettingsEndpoint: PropTypes.string,
        };
    }

    componentDidMount() {
        // FIXME: This is not optimal but will work to get the token into the store
        mountedStore.dispatch(accessTokenStored(this.props.accessToken));
        mountedStore.dispatch(idTokenStored(cloneDeep(this.props.idToken)));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.accessToken !== nextProps.accessToken) {
            // FIXME: This is not optimal but will work to get the token into the store
            mountedStore.dispatch(accessTokenStored(nextProps.accessToken));
        }
        if (isEqual(this.props.idToken, nextProps.idToken)) {
            mountedStore.dispatch(idTokenStored(cloneDeep(nextProps.idToken)));
        }
    }

    render() {
        const { idToken, userSettingsEndpoint } = this.props;

        const accountPopover = (
            <AccountPopoverContainer
                backendUrl={userSettingsEndpoint}
                idToken={idToken}
                onLogout={broadcastLogout}
            />
        );

        return (
            <Provider store={mountedStore}>
                <ConnectedIntlProvider idToken={idToken}>
                    <ActionBarItem id={'accountMenu'}>
                        <ActionBarItem.Icon>
                            <span className={'icon rioglyph rioglyph-user'}></span>
                        </ActionBarItem.Icon>
                        <ActionBarItem.Popover title={<FormattedMessage id={'intl-msg:popover.title'} />}>
                            {accountPopover}
                        </ActionBarItem.Popover>
                        <UserSettingsDialog backendUrl={userSettingsEndpoint} />
                    </ActionBarItem>
                </ConnectedIntlProvider>
            </Provider>
        );
    }
}
