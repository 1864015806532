import whiteProperties from '../constants/whitelistProperties';
import pick from 'lodash/fp/pick';
import { translate } from '../lang/languageData';
import { MODULE_NAME } from '../constants/globals';

export const COMMON_ERROR_ID = `intl-msg:error.commonError`;
export const PARSING_ERROR_MESSAGE = `${MODULE_NAME} error could not be parsed`;

export function showError() {
    // eslint-disable-next-line no-alert
    alert(translate(COMMON_ERROR_ID));
}

export function cleanContext(context) {
    return pick(whiteProperties, context);
}

//send to https://e0f3d9ba9f4048f2b95ff66808598600@sentry.io/267222 for LOCAL testing purposes
//configured in platform/frontend/web/index.html
export function sendErrorWithContext(exception, context) {

    if (typeof context === 'object' && Object.keys(context).length > 0) {
        const whiteContext = cleanContext(context);
        window.Raven.setExtraContext(whiteContext);
    }
    const msg = `${MODULE_NAME} error: ${exception.message}`;
    window.Raven.captureMessage(msg, {
        tags: {
            module: MODULE_NAME,
        },
    });
}

export function sendError(exception, funcName) {
    const msg = `${MODULE_NAME} ${funcName}: ${exception.message}`;
    if (window.Raven) {
        window.Raven.captureMessage(msg, { tags: {
            module: MODULE_NAME,
        } });
    }
}

export function handleServiceException(e, contextOrDescription, showCommonError = false) {
    const handledError = {};
    try {
        const parsedBody = JSON.parse(e.body);
        if (parsedBody.errors && parsedBody.errors.length) {
            handledError.error = parsedBody.errors[0];
            return handledError;
        }
    } catch (e2) {
        e2.message = PARSING_ERROR_MESSAGE;
        e.wrappedException = e2;
    }
    handledError.error = { status: e.status };
    if (showCommonError) {
        showError();
    }
    if (contextOrDescription && typeof contextOrDescription === 'object') {
        sendErrorWithContext(e, contextOrDescription);
    } else {
        sendError(e, contextOrDescription);
    }
    return handledError;
}
