import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export default class TextFormControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            touched: false,
        };

        this.validateInput = this.validateInput.bind(this);
        this.getVisibleInputState = this.getVisibleInputState.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const newState = { ...this.state };
        newState.touched = nextProps !== this.state.value;
        newState.value = nextProps.value;

        this.setState(newState);
    }

    validateInput() {
        const { validateInput } = this.props;
        const { value } = this.state;
        let result = null;

        if (validateInput) {
            result = validateInput(value);
        }

        return result;
    }

    getVisibleInputState() {
        const { touched } = this.state;
        let result = null;

        const externalResult = this.validateInput();
        if (touched) {
            result = externalResult;
        }

        return result === null ? null : 'error';
    }

    getVisibleError() {
        const { touched } = this.state;
        let result = '';

        const externalResult = this.validateInput();
        if (touched) {
            result = externalResult;
        }

        return result === null ? '' : result;
    }

    onInputChange(event) {
        const { onInputChange } = this.props;

        const newState = { ...this.state };
        newState.value = event.target.value;
        this.setState(newState);

        if (onInputChange) {
            onInputChange(event);
        }
    }

    onBlur() {
        const newState = { ...this.state };
        newState.touched = true;

        this.setState(newState);
    }

    render() {
        const { componentClass, id, labelId } = this.props;

        return (
            <FormGroup
                controlId={`${id}FormGroupInput`}
                validationState={this.getVisibleInputState()}>
                <ControlLabel>
                    <FormattedMessage id={labelId} defaultMessage={labelId}/>
                </ControlLabel>
                <FormControl
                    type='text'
                    componentClass={componentClass}
                    placeholder={this.context.intl.formatMessage({ id: labelId })}
                    onChange={(event) => this.onInputChange(event)}
                    onBlur={this.onBlur.bind(this)}
                    value={this.state.value}/>
                <HelpBlock>{this.getVisibleError()}</HelpBlock>
            </FormGroup>
        );
    }

}

Component.contextTypes = {
    intl: PropTypes.object.isRequired,
};

TextFormControl.defaultProps = {
    componentClass: 'input',
    value: '',
};

TextFormControl.propTypes = {
    id: PropTypes.string.isRequired,
    componentClass: PropTypes.string,
    labelId: PropTypes.string.isRequired,
    value: PropTypes.string,
    validateInput: PropTypes.func,
    onInputChange: PropTypes.func,
};
