import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ERROR_NOT_EMPTY_ID = `intl-msg:inputFields.error.notEmpty`;
export function createValidationFunction(validationList) {
    return value => {
        const resultList = validationList
            .map(validation => validation[0](value) ? null : validation[1])
            .filter(result => result !== null);

        return resultList.length > 0 ? resultList[0] : null;
    };
}

export function isEmpty(value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    throw new Error('isEmpty: input not valid');
}

export function isNotEmpty(value) {
    return !isEmpty(value);
}

export function getNameValidation() {

    return createValidationFunction([
        [isNotEmpty, <FormattedMessage id={ERROR_NOT_EMPTY_ID} />]]);
}
