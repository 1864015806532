import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import SpinnerText from './SpinnerText';
import { FormattedMessage } from 'react-intl';
import { Select, ControlLabel, FormGroup, SpinnerInfoBox } from 'rio-uikit';
import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';
import { defaultMessages } from '../lang/languageData';
import { SUPPORTED_LOCALES, translations } from '../lang';

export const LANGUAGE_CLASS = 'language-tab';

export const HEADLINE_ID = `intl-msg:settingsDialog.tab.language.headline`;
export const LANGUAGE_LABEL_ID = `intl-msg:settingsDialog.tab.language.label.language`;
export const LANGUAGE_FIELD_ID = `intl-msg:settingsDialog.tab.language.languageInputField`;
export const LANGUAGE_SELECT_PLACEHOLDER = `intl-msg:settingsDialog.tab.language.select.placeholder`;
export const SAVE_BUTTON_LABEL_ID = `intl-msg:settingsDialog.tab.language.button.save.label`;
export const ERROR_BASE_ID = `intl-msg:settingsDialog.tab.language.error.backend`;
export const LOCALES_BASE_ID = `intl-msg:settingsDialog.tab.language.locale`;

const renderMessage = (id, defaultMessage = id) => {
    return (
        <FormattedMessage id={id} defaultMessage={defaultMessage}/>
    );
};

const LOCALE_ID_PREFIX = 'intl-msg:settingsDialog.tab.language.locale.';

export const sortLocales = (locales, messagesOfLocale = []) => {
    return locales
        .map(locale => {
            return {
                locale: locale,
                displayName: messagesOfLocale[`${LOCALE_ID_PREFIX}${locale}`],
            };
        })
        .sort((a, b) => {
            const displayNameA = a.displayName || '';
            const displayNameB = b.displayName || '';

            /* eslint-disable no-console */
            console.assert(displayNameA, `translation missing for key: ${LOCALE_ID_PREFIX}${a.locale}`);
            console.assert(displayNameB, `translation missing for key: ${LOCALE_ID_PREFIX}${b.locale}`);

            return displayNameA.toLocaleLowerCase().localeCompare(displayNameB.toLocaleLowerCase());
        })
        .map(item => item.locale);
};

export const getLanguageDropDownItems = (selectedLocale, supportedLocales) => {
    const options = [];
    const sortedLocales = sortLocales(supportedLocales, defaultMessages);
    sortedLocales.forEach((locale) => {
        options.push({
            id: locale,
            label: renderMessage(`${LOCALES_BASE_ID}.${locale}`),
            selected: selectedLocale === locale,
        });
    });
    return options;
};

export default class LanguageTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locale: this.props.locale,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.hasError(nextProps)) {
            this.setState({
                locale: this.props.locale,
            });
        }
    }

    hasError(props = this.props) {
        return props.error !== '';
    }

    getErrorStateString() {
        return this.hasError() ? 'error' : null;
    }

    formatMessage(id) {
        return this.context.intl.formatMessage({ id: id });
    }

    isOnConfirmDisabled() {
        return !includes(this.state.locale, SUPPORTED_LOCALES) ||
            this.props.isPending ||
            this.state.locale === this.props.locale;
    }

    renderConfirmText() {
        const text = renderMessage(SAVE_BUTTON_LABEL_ID);
        return this.props.isPending ? <SpinnerInfoBox text={text} /> : text;
    }

    renderButtonToolbar() {
        const isDisabled = this.isOnConfirmDisabled() ? 'disabled' : '';
        return (
            <div className='btn-toolbar pull-right'>
                <div className={`btn btn-primary ${isDisabled}`} onClick={this.onConfirm}>
                    {this.renderConfirmText()}
                </div>
            </div>
        );
    }

    renderFormGroup(control, id, className = '') {
        return (
            <FormGroup
                className={`foo ${className}`}
                controlId={`${id}FormGroupInput`}
                validationState={this.getErrorStateString()}>
                <ControlLabel>
                    {renderMessage(LANGUAGE_LABEL_ID)}
                </ControlLabel>
                {control}
                <span className='help-block'>{this.getErrorMessage()}</span>
            </FormGroup>
        );
    }

    getErrorMessage() {
        return this.hasError() ?
            renderMessage(`${ERROR_BASE_ID}.${this.props.error}`,
                this.formatMessage(`${ERROR_BASE_ID}.unknown`)) : null;
    }

    onInputChange(selectedItem) {
        this.setState({
            locale: selectedItem.id,
        });
    }

    onConfirm() {
        if (!this.isOnConfirmDisabled()) {
            this.props.updateLanguage(this.state.locale);
        }
    }

    renderLanguageSelectFormGroup() {
        return this.renderFormGroup(
            <Select
                classNames={['inputLanguage']}
                placeholder={this.formatMessage(LANGUAGE_SELECT_PLACEHOLDER)}
                onChange={this.onInputChange}
                options={getLanguageDropDownItems(this.state.locale, SUPPORTED_LOCALES)}
            />,
            'locale', LANGUAGE_FIELD_ID
        );
    }

    renderHeadline() {
        return (
            <h4>{renderMessage(HEADLINE_ID)}</h4>
        );
    }

    render() {
        return (
            <div className={`${LANGUAGE_CLASS}`}>
                {this.renderHeadline()}
                {this.renderLanguageSelectFormGroup()}
                <br/>
                {this.renderButtonToolbar()}
            </div>
        );
    }
}

Component.contextTypes = {
    intl: PropTypes.object,
};

LanguageTab.propTypes = {
    locale: PropTypes.oneOf([...Object.keys(translations), '']).isRequired,
    isPending: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    updateLanguage: PropTypes.func,
};

LanguageTab.defaultProps = {
    isPending: false,
    error: '',
    updateLanguage: noop,
};
