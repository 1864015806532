import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { editUser, getUserInfo, updateLanguage, getLanguage } from '../services/settingsServices';
import {
    EDIT_USER_ACTION,
    FETCH_USER_INFO_ACTION,
    FETCH_LANGUAGE_ACTION,
    fetchLanguageSuccessAction,
    UPDATE_LANGUAGE_ACTION,
    updateLanguageStartPendingAction,
    updateLanguageStopPendingAction,
    updateLanguageFailureAction,
    fetchLanguageFailureAction,
    updateLanguageSuccessAction,
    fetchUserInfoSuccessAction,
    editUserSuccessAction,
    editUserStartPendingAction,
    editUserStopPendingAction,
} from '../actions/settingsActions';
import { selectAccessToken, selectIdToken } from '../selectors/settingsSelectors';

import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '../constants/api';

export function* doFetchUserInfoSaga(action) {
    const idToken = yield select(selectIdToken);

    if (idToken) {
        yield put(fetchUserInfoSuccessAction({
            email: idToken.email,
            firstName: idToken.given_name,
            lastName: idToken.family_name,
            preferredLanguage: idToken.locale,
        }));
        return;
    }

    const token = yield select(selectAccessToken);
    const userInfo = yield call(getUserInfo, action.payload, token);
    if (typeof userInfo !== 'undefined') {
        yield put(fetchUserInfoSuccessAction(userInfo));
    }
}

export function* fetchUserInfoSaga() {
    yield takeLatest(FETCH_USER_INFO_ACTION, doFetchUserInfoSaga);
}

export function* doEditUserSaga(action) {

    const token = yield select(selectAccessToken);
    const user = action.payload.userInfo;
    const backend = action.payload.backendUrl;
    yield put(editUserStartPendingAction());
    const userInfo = yield call(editUser, backend, user, token);
    if (typeof userInfo !== 'undefined') {
        yield put(editUserSuccessAction(userInfo));

        // FIXME: also trigger event on react component
        document.dispatchEvent(new CustomEvent(EVENT_USER_PROFILE_CHANGED, {
            detail: userInfo,
        }));
    }
    yield (put(editUserStopPendingAction()));
}

export function* doFetchLanguageSaga(action) {
    const idToken = yield select(selectIdToken);

    if (idToken) {
        yield put(fetchLanguageSuccessAction(idToken.locale));
        return;
    }

    const token = yield select(selectAccessToken);
    const languageData = yield call(getLanguage, action.payload, token);
    if (languageData && languageData.preferredLanguage) {
        yield put(fetchLanguageSuccessAction(languageData.preferredLanguage));
    } else {
        yield put(fetchLanguageFailureAction());
    }
}

export function* fetchLanguageSaga() {
    yield takeLatest(FETCH_LANGUAGE_ACTION, doFetchLanguageSaga);
}

export function getErrorCode(error) {
    if (error) {
        if (error.code) {
            return error.code;
        } else if (error.status) {
            return error.status;
        }
    }
    return 'unknown';
}

export function* doUpdateLanguageSaga(action) {
    try {
        const token = yield select(selectAccessToken);
        const language = action.payload.language;
        const backend = action.payload.backendUrl;
        yield put(updateLanguageStartPendingAction());
        const response = yield call(updateLanguage, backend, language, token);
        if (response.error) {
            yield put(updateLanguageFailureAction(getErrorCode(response.error)));
            yield put(updateLanguageStopPendingAction());
        } else {
            yield put(updateLanguageSuccessAction(language));
            yield put(updateLanguageStopPendingAction());

            document.dispatchEvent(new CustomEvent(EVENT_USER_LANGUAGE_CHANGED, {
                detail: {
                    language: language,
                },
            }));
        }
    } catch (e) {
        yield put(updateLanguageFailureAction('unknown'));
        yield put(updateLanguageStopPendingAction());
    }
}

export function* updateLanguageSaga() {
    yield takeLatest(UPDATE_LANGUAGE_ACTION, doUpdateLanguageSaga);
}

export function* editUserSaga() {
    yield takeLatest(EDIT_USER_ACTION, doEditUserSaga);
}

