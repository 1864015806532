export const SHOW_SETTINGS_DIALOG_ACTION = 'SHOW_SETTINGS_DIALOG_ACTION';
export function showSettingsDialogAction() {
    return {
        type: SHOW_SETTINGS_DIALOG_ACTION,
    };
}

export const HIDE_SETTINGS_DIALOG_ACTION = 'HIDE_SETTINGS_DIALOG_ACTION';
export function hideSettingsDialogAction() {
    return {
        type: HIDE_SETTINGS_DIALOG_ACTION,
    };
}

export const CHANGE_TAB_ACTION = 'CHANGE_TAB_ACTION';
export function changeTabAction(tabIndex) {
    return {
        type: CHANGE_TAB_ACTION,
        payload: tabIndex,
    };
}
