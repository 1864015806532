import {
    ACCESS_TOKEN_STORED,
    EDIT_USER_START_PENDING_ACTION,
    EDIT_USER_STOP_PENDING_ACTION,
    EDIT_USER_SUCCESS_ACTION,
    FETCH_LANGUAGE_FAILURE_ACTION,
    FETCH_LANGUAGE_SUCCESS_ACTION,
    FETCH_USER_INFO_SUCCESS_ACTION,
    ID_TOKEN_STORED,
    UPDATE_LANGUAGE_FAILURE_ACTION,
    UPDATE_LANGUAGE_START_PENDING_ACTION,
    UPDATE_LANGUAGE_STOP_PENDING_ACTION,
    UPDATE_LANGUAGE_SUCCESS_ACTION,
} from '../actions/settingsActions';
import { DEFAULT_LOCALE, getLanguageData, getSupportedLocale } from '../lang';
import { defaultMessages } from '../lang/languageData';

export const initialState = {
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
    },
    userInfoPending: false,
    newsletter: {
        subscribed: false,
        subscriptionDate: '',
    },
    language: {
        locale: DEFAULT_LOCALE,
        messages: defaultMessages,
        isPending: false,
        error: '',
    },
    session: {
        accessToken: '',
        idToken: null,
    },
};

function handleFetchUserInfoSuccessAction(state, userInfo) {
    const newState = { ...state };
    newState.userInfo = userInfo;

    return newState;
}

function handleFetchLanguageSuccessAction(state, language) {
    const newState = { ...state };
    const supportedLocale = getSupportedLocale(language);
    newState.language.locale = supportedLocale;
    newState.language.messages = getLanguageData(supportedLocale);

    return newState;
}

function handleFetchLanguageFailureAction(state) {
    const newState = { ...state };
    newState.language.locale = DEFAULT_LOCALE;
    newState.language.messages = getLanguageData(DEFAULT_LOCALE);

    return newState;
}

function handleUpdateLanguagePendingAction(state, isPending) {
    const newState = { ...state };
    newState.language.isPending = isPending;
    return newState;
}

function handleEditUserSuccessAction(state, userInfo) {
    const newState = { ...state };
    newState.userInfo = userInfo;

    if (newState.session.idToken) {
        /* eslint-disable camelcase */
        newState.session.idToken.given_name = userInfo.firstName;
        newState.session.idToken.family_name = userInfo.lastName;
        /* eslint-enable camelcase */
    }

    return newState;
}

function handleEditUserStartPendingAction(state) {
    const newState = { ...state };
    newState.userInfoPending = true;

    return newState;
}

function handleEditUserStopPendingAction(state) {
    const newState = { ...state };
    newState.userInfoPending = false;

    return newState;
}

// eslint-disable-next-line complexity
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACCESS_TOKEN_STORED:
            return {
                ...state,
                session: {
                    accessToken: action.payload,
                },
            };
        case FETCH_USER_INFO_SUCCESS_ACTION:
            return handleFetchUserInfoSuccessAction(state, action.payload);
        case EDIT_USER_SUCCESS_ACTION:
            return handleEditUserSuccessAction(state, action.payload);
        case EDIT_USER_START_PENDING_ACTION:
            return handleEditUserStartPendingAction(state);
        case EDIT_USER_STOP_PENDING_ACTION:
            return handleEditUserStopPendingAction(state);
        case FETCH_LANGUAGE_SUCCESS_ACTION:
            return handleFetchLanguageSuccessAction(state, action.payload);
        case FETCH_LANGUAGE_FAILURE_ACTION:
            return handleFetchLanguageFailureAction(state);
        case ID_TOKEN_STORED:
            return {
                ...state,
                session: {
                    ...state.session,
                    idToken: action.payload,
                },
            };
        case UPDATE_LANGUAGE_SUCCESS_ACTION: {
            const newState = { ...state };
            const supportedLocale = getSupportedLocale(action.payload);
            newState.language.locale = supportedLocale;
            newState.language.messages = getLanguageData(supportedLocale);
            newState.language.error = '';

            if (newState.session.idToken) {
                newState.session.idToken.locale = action.payload;
            }

            return newState;
        }
        case UPDATE_LANGUAGE_FAILURE_ACTION: {
            const newState = { ...state };
            newState.language.error = action.payload;
            return newState;
        }
        case UPDATE_LANGUAGE_START_PENDING_ACTION:
            return handleUpdateLanguagePendingAction(state, true);
        case UPDATE_LANGUAGE_STOP_PENDING_ACTION:
            return handleUpdateLanguagePendingAction(state, false);
        default:
            return { ...state };
    }
};
