import getOr from 'lodash/fp/getOr';
import { initialState } from '../reducers/settingsReducer';
import { defaultMessages } from '../lang/languageData';

export const selectAccessToken = getOr('', 'settings.session.accessToken');

export const selectIdToken = getOr(null, 'settings.session.idToken');

export function selectUserInfo(state) {
    const userInfoPropPath = `settings.userInfo`;

    const defaultValue = getOr({ firstName: '', lastName: '', email: '' }, userInfoPropPath, initialState);

    return getOr(defaultValue, userInfoPropPath, state);
}

export function selectNewsletter(state) {
    const newsletterPropPath = `settings.newsletter`;

    const defaultValue = getOr({ subscribed: false }, newsletterPropPath, initialState);

    return getOr(defaultValue, newsletterPropPath, state);
}

export function selectLanguage(state) {
    const descriptor = 'settings.language';
    const defaultValue = getOr({
        locale: '',
        messages: defaultMessages,
        isPending: false,
        error: '',
    }, descriptor, initialState);

    return getOr(defaultValue, descriptor, state);
}

export function selectUserInfoPending(state) {
    const userInfoPendingPath = `settings.userInfoPending`;
    const defaultValue = getOr(false, userInfoPendingPath, initialState);
    return getOr(defaultValue, userInfoPendingPath, state);
}
