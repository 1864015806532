import { addLocaleData } from 'react-intl';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import et from 'react-intl/locale-data/et';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import lt from 'react-intl/locale-data/lt';
import lv from 'react-intl/locale-data/lv';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import sk from 'react-intl/locale-data/sk';
import bg from 'react-intl/locale-data/bg';
import nb from 'react-intl/locale-data/nb';
import hr from 'react-intl/locale-data/hr';
import sl from 'react-intl/locale-data/sl';
import fi from 'react-intl/locale-data/fi';
import el from 'react-intl/locale-data/el';
import hu from 'react-intl/locale-data/hu';
import no from 'react-intl/locale-data/no';
import sv from 'react-intl/locale-data/sv';

import flow from 'lodash/fp/flow';
import has from 'lodash/fp/has';
import map from 'lodash/fp/map';
import zipObject from 'lodash/fp/zipObject';

export const DEFAULT_LOCALE = 'en-GB';

addLocaleData([
    ...bg,
    ...de,
    ...es,
    ...fr,
    ...it,
    ...nb,
    ...pl,
    ...sk,
    ...cs,
    ...el,
    ...et,
    ...hr,
    ...lt,
    ...nl,
    ...pt,
    ...sl,
    ...da,
    ...en,
    ...fi,
    ...hu,
    ...lv,
    ...no,
    ...ro,
    ...sv,
]);

// Used to list languges in the UI the user can select in the Application
// as well as to check translation for the accountmenu itself
export const SUPPORTED_LOCALES = [
    'bg-BG',
    'cs-CZ',
    'da-DK',
    'de-DE',
    'en-GB',
    'es-ES',
    'el-GR',
    'et-EE',
    'fi-FI',
    'fr-FR',
    'hr-HR',
    'hu-HU',
    'it-IT',
    'lt-LT',
    'lv-LV',
    'nb-NO',
    'nl-NL',
    'pl-PL',
    'pt-PT',
    'pt-BR',
    'ro-RO',
    'sk-SK',
    'sl-SI',
    'sv-SE',
].sort();

const baseTranslations = flow(
    // eslint-disable-next-line global-require
    map(locale => require(`./lang/translations/${locale}.json`)),
    zipObject(SUPPORTED_LOCALES)
)(SUPPORTED_LOCALES);

export const localeMapping = {
    de: 'de-DE',
    da: 'da-DK',
    en: 'en-GB',
    es: 'es-ES',
    et: 'et-EE',
    fr: 'fr-FR',
    it: 'it-IT',
    lt: 'lt-LT',
    lv: 'lv-LV',
    nb: 'nb-NO',
    no: 'nb-NO',
    nl: 'nl-NL',
    pl: 'pl-PL',
    pt: 'pt-PT',
    ro: 'ro-RO',
    sk: 'sk-SK',
};

// eslint-disable-next-line no-return-assign
SUPPORTED_LOCALES.forEach(locale => localeMapping[locale] = locale);

export const translations = {};

// eslint-disable-next-line guard-for-in
for (const key in localeMapping) {
    translations[key] = baseTranslations[localeMapping[key]];
}

if (!has(DEFAULT_LOCALE, translations)) {
    // eslint-disable-next-line no-console
    console.warn(
        `The configured default locale "${DEFAULT_LOCALE}" is not present.`
    );
}

const extractLanguage = locale => {
    const newLocale = locale || DEFAULT_LOCALE;
    return newLocale.split('-')[0];
};

export const configureLocale = (navigator, lookup) => {

    const getSupportedLocaleFromTranslations = languageData => flow(
        locale => has(locale, languageData) ? locale : extractLanguage(locale),
        locale => has(locale, languageData) ? locale : navigator.language,
        locale => has(locale, languageData) ? locale : DEFAULT_LOCALE
    );

    return {
        getLanguageData: locale => lookup[locale],
        getSupportedLocale: getSupportedLocaleFromTranslations(lookup),
    };
};

export const { getLanguageData, getSupportedLocale } = configureLocale(navigator, translations);
