import {
    editUserSaga,
    fetchLanguageSaga,
    fetchUserInfoSaga,
    updateLanguageSaga,
} from './settingsSagas';

/* Include here all watcher sagas! */
export const rootSaga = [
    fetchUserInfoSaga,
    editUserSaga,
    fetchLanguageSaga,
    updateLanguageSaga,
];

export function runSagas(middleware) {
    rootSaga.forEach((saga) => {
        middleware.run(saga);
    });
}
