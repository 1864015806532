import React from 'react';
import ReactDOM from 'react-dom';
import * as Uikit from 'rio-uikit';

if (!React) {
    throw new Error('"rio-accountmenu" needs a global React on the page');
}

if (!ReactDOM) {
    throw new Error('"rio-accountmenu" needs a global ReactDOM on the page');
}

const parts = it => it.split('.').map(int => parseInt(int, 10));

const isCompatible = (required, version) => {
    const requiredParts = parts(required);
    const versionParts = parts(version);

    for (let i = 0; i < requiredParts.length; i += 1) {
        if (requiredParts[i] > versionParts[i]) {
            return false;
        }
    }

    return true;
};

const requiredUikit = '0.12.0';
if (!Uikit || !Uikit.VERSION || !isCompatible(requiredUikit, Uikit.VERSION)) {
    throw new Error(`"rio-accountmenu" needs a global RioUikit >= ${requiredUikit} on the page ` +
        `but instead got ${(Uikit && Uikit.VERSION)}`);
}

import DefaultAccountMenu from './components/DefaultAccountMenu';

import {
    EVENT_USER_LANGUAGE_CHANGED,
    EVENT_USER_LOGGED_OUT,
    EVENT_USER_PROFILE_CHANGED,
} from './constants/api';

if (typeof window !== 'undefined') {
    window.RioAccountMenuSnapshot = {
        DefaultAccountMenu,
        EVENT_USER_LANGUAGE_CHANGED,
        EVENT_USER_LOGGED_OUT,
        EVENT_USER_PROFILE_CHANGED,
    };
}

export { // eslint-disable-line no-undef
    DefaultAccountMenu,
    EVENT_USER_LANGUAGE_CHANGED,
    EVENT_USER_LOGGED_OUT,
    EVENT_USER_PROFILE_CHANGED,
};
