import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'rio-uikit';
import noop from 'lodash/fp/noop';

export default class TabStructuredDialog extends PureComponent {

    renderHeader() {
        return this.props.title;
    }

    renderFooter() {
        const { onClose, closeButtonLabel } = this.props;

        return (
            <div className='btn-toolbar pull-right'>
                <div className='btn-group'>
                    <div className='btn btn-primary' onClick={onClose}>
                        {closeButtonLabel}
                    </div>
                </div>
            </div>
        );
    }

    setNewIndex(event, index) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onTabChange(index);
    }

    renderTabLink(tab, index) {
        const { tabIndex } = this.props;
        const activeClass = (index === tabIndex) ? 'active' : '';

        return (
            <li key={`link${index}`} className={activeClass}>
                <a onClick={(event) => this.setNewIndex(event, index)}>
                    {tab.headline}
                </a>
            </li>
        );
    }

    renderTabLinks(tabs) {
        return (
            <ul className='nav nav-pills nav-stacked'>
                {tabs.map((tab, index) => this.renderTabLink(tab, index))}
            </ul>
        );
    }

    renderContent() {
        const { tabs, tabIndex } = this.props;

        return (
            <div className='container-fluid row'>
                <div className='row'>
                    <div className='col-xs-4 navigation'>
                        {this.renderTabLinks(tabs)}
                    </div>
                    <div className='col-xs-8 content'>
                        {tabs[tabIndex].content}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { show, onClose } = this.props;

        return (
            <Dialog
                show={show}
                title={this.renderHeader()}
                body={this.renderContent()}
                footer={this.renderFooter()}
                onHide={() => onClose()}
                className='UserSettingsDialog'
                showCloseButton={true}
            />
        );
    }

}

TabStructuredDialog.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.node,
    onClose: PropTypes.func,
    closeButtonLabel: PropTypes.node,
    onTabChange: PropTypes.func,
    tabIndex: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        headline: PropTypes.node,
        content: PropTypes.node,
    })),
};

TabStructuredDialog.defaultProps = {
    show: false,
    title: '',
    onClose: noop,
    onTabChange: noop,
    closeButtonLabel: '',
    tabIndex: 0,
    tabs: [],
};
