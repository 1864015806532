import { combineReducers } from 'redux';
import userSettingsDialogReducer, { initialState as initialUserSettingsDialogState } from './settingsDialogReducer';
import settingsReducer, { initialState as initialSettingsState } from './settingsReducer';

export const initialState = {
    userSettingsDialog: initialUserSettingsDialogState,
    settings: initialSettingsState,
};

export default combineReducers({
    userSettingsDialog: userSettingsDialogReducer,
    settings: settingsReducer,
});
