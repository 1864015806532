import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { initialState } from '../reducers/settingsDialogReducer';

export function selectSettingsDialogVisibility(state) {
    const showProp = 'show';
    const showPropPath = `userSettingsDialog.${showProp}`;
    const defaultResult = getOr(false, showProp, initialState);

    return getOr(defaultResult, showPropPath, state);
}

export function selectTabIndex(state) {
    return get('userSettingsDialog.tabIndex', state);
}
