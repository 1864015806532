import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';

import { handleServiceException, sendError, showError } from '../utils/errorHandling';

export const NEWSLETTER_RESOURCE_PATH = '/user/settings/newsletter';
export const LANGUAGE_RESOURCE_PATH = '/user/settings/language';
export const USER_INFO_RESOURCE_PATH = '/whoami';
export const EDIT_USER_RESOURCE_PATH = '/users';

const reject = response => {
    if (response.text) {
        return response.text().then(body => {
            const error = new Error(response.statusText);
            error.status = response.status;
            error.body = body;
            return Promise.reject(error);
        });
    }

    const error = new Error(response.statusText);
    error.status = response.status;
    error.body = '';
    return Promise.reject(error);
};

const authorizeFetch = (token, fetch = window.fetch) => (url, options) => {
    const mergedOptions = merge(cloneDeep(options), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return fetch(url, mergedOptions);
};

const jsonOrReject = response => response.ok ? response.json() : reject(response);

// eslint-disable-next-line no-use-before-define
export function getNewsletter(backend, token, fetch = fetch) {
    const configuredFetch = authorizeFetch(token, fetch);

    const url = `${backend}${NEWSLETTER_RESOURCE_PATH}`;

    return configuredFetch(url, {
        headers: {
            Accept: 'application/json',
        },
        method: 'GET',
    }).then(jsonOrReject).catch(err => {
        sendError(err, 'getNewsletter');
    });
}

export function getLanguage(backend, token, fetch) {
    const configuredFetch = authorizeFetch(token, fetch);

    const url = `${backend}${LANGUAGE_RESOURCE_PATH}`;

    return configuredFetch(url, {
        headers: {
            Accept: 'application/json',
        },
        method: 'GET',
    }).then(jsonOrReject).catch(err => {
        sendError(err, 'getLanguage');
    });
}

// eslint-disable-next-line no-use-before-define
export function getUserInfo(backend, token, fetch = fetch) {
    const configuredFetch = authorizeFetch(token, fetch);

    const url = `${backend}${USER_INFO_RESOURCE_PATH}`;

    return configuredFetch(url, {
        headers: {
            Accept: 'application/json',
        },
        method: 'GET',
    }).then(jsonOrReject).catch(err => {
        if (err.status !== 401) {
            sendError(err, 'getUserInfo');
        }
    });
}

// eslint-disable-next-line no-use-before-define
export function editUser(backend, user, token, fetch = fetch) {
    const configuredFetch = authorizeFetch(token, fetch);

    const url = `${backend}${EDIT_USER_RESOURCE_PATH}`;

    return configuredFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
        method: 'PUT',
    }).then(jsonOrReject).catch((err) => {
        showError();
        sendError(err, 'editUser');
    });
}

// eslint-disable-next-line no-use-before-define
export function updateLanguage(backend, language, token, fetch = fetch) {
    const configuredFetch = authorizeFetch(token, fetch);

    const url = `${backend}${LANGUAGE_RESOURCE_PATH}`;
    return configuredFetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                preferredLanguage: language,
            }
        ),
        method: 'PUT',
    }).then((response) => {
        return {
            edited: response.status,
        };
    }).catch(err => {
        return handleServiceException(err, 'updateLanguage', false);
    });
}
