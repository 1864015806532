import {
    SHOW_SETTINGS_DIALOG_ACTION,
    HIDE_SETTINGS_DIALOG_ACTION,
    CHANGE_TAB_ACTION,
} from '../actions/settingsDialogActions';

export const initialState = {
    show: false,
    tabIndex: 0,
};

function handleShowSettingsDialogAction(state) {
    const newState = { ...state };
    newState.show = true;

    return newState;
}

function handleHideSettingsDialogAction(state) {
    const newState = { ...state };
    newState.show = false;

    return newState;
}

function handleChangeTabAction(state, action) {
    return {
        ...state,
        tabIndex: action.payload,
    };
}

export default function settingsDialogReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SHOW_SETTINGS_DIALOG_ACTION: return handleShowSettingsDialogAction(state);
        case HIDE_SETTINGS_DIALOG_ACTION: return handleHideSettingsDialogAction(state);
        case CHANGE_TAB_ACTION: return handleChangeTabAction(state, action);
        default: return { ...state };
    }
}
