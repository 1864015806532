import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/fp/noop';

export const NEWSLETTER_CLASS = 'newsletter-tab';

export const HEADLINE_ID = `intl-msg:settingsDialog.tab.newsletter.headline`;
export const RIO_LABEL_ID = `intl-msg:settingsDialog.tab.newsletter.label.rio`;
export const NOT_SUBSCRIBED_ID = `intl-msg:settingsDialog.tab.newsletter.subscription.negative`;
export const SUBSCRIBED_ID = `intl-msg:settingsDialog.tab.newsletter.subscription.positive`;
export const SAVE_BUTTON_LABEL_ID = `intl-msg:settingsDialog.tab.newsletter.button.save.label`;

export default class NewsletterTab extends Component {

    renderMessage(id) {
        return (
            <FormattedMessage id={id} defaultMessage={id}/>
        );
    }

    renderHeadLine() {
        return (
            <h4>{this.renderMessage(HEADLINE_ID)}</h4>
        );
    }

    renderSubscription(labelId, subscribed) {
        const labelMessage = this.renderMessage(labelId);
        const subscriptionId = subscribed ? SUBSCRIBED_ID : NOT_SUBSCRIBED_ID;
        const subscriptionMessage = this.renderMessage(subscriptionId);

        return (
            <div>{labelMessage}: {subscriptionMessage}</div>
        );
    }

    renderButtonToolbar() {

        // TODO: Comment this in when newsletter status can be changed
        // return (
        //     <div className='btn-toolbar pull-right'>
        //         <div className='btn btn-primary' onClick={this.props.onSave}>
        //             {this.renderMessage(SAVE_BUTTON_LABEL_ID)}
        //         </div>
        //     </div>
        // );

        return '';
    }

    render() {
        return (
            <div className={`${NEWSLETTER_CLASS}`}>
                {this.renderHeadLine()}
                {this.renderSubscription(RIO_LABEL_ID, this.props.subscribed)}
                <br/>
                {this.renderButtonToolbar()}
            </div>
        );
    }
}

NewsletterTab.propTypes = {
    subscribed: PropTypes.bool,
    onSave: PropTypes.func,
};

NewsletterTab.defaultProps = {
    subscribed: false,
    onSave: noop,
};
