import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';
import pick from 'lodash/fp/pick';
import { FormattedMessage } from 'react-intl';
import { selectSettingsDialogVisibility, selectTabIndex } from '../selectors/settingsDialogSelectors';
import {
    selectLanguage,
    selectNewsletter,
    selectUserInfo,
    selectUserInfoPending,
} from '../selectors/settingsSelectors';
import { changeTabAction, hideSettingsDialogAction } from '../actions/settingsDialogActions';
import TabStructuredDialog from '../components/TabStructuredDialog';
import NewsletterTab from '../components/NewsletterTab';
import PersonalDataTab from '../components/PersonalDataTab';
import LanguageTab from '../components/LanguageTab';
import {
    editUserAction,
    fetchUserInfoAction,
    updateLanguageAction,
} from '../actions/settingsActions';
import { translations } from '../lang';

export const TITLE_ID = `intl-msg:settingsDialog.title`;
export const CLOSE_BUTTON_LABEL_ID = `intl-msg:settingsDialog.button.close.label`;
export const PERSONAL_DATA_TAB_HEADLINE_ID = `intl-msg:settingsDialog.tab.personalData.headline`;
export const NEWSLETTER_TAB_HEADLINE_ID = `intl-msg:settingsDialog.tab.newsletter.headline`;
export const LANGUAGE_TAB_HEADLINE_ID = `intl-msg:settingsDialog.tab.language.headline`;

export class SettingsDialogContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(tabIndex) {
        this.props.changeTabAction(tabIndex);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.show && nextProps.show) {
            if (!this.props.userInfo.lastName) {
                this.props.fetchUserInfo();
            }
        }
    }

    renderMessage(id) {
        return (
            <FormattedMessage id={id} defaultMessage={id}/>
        );
    }

    render() {
        const { show, onClose, tabIndex } = this.props;

        if (!show) {
            return null;
        }

        const personalDataTabProps = {
            firstName: this.props.userInfo.firstName,
            lastName: this.props.userInfo.lastName,
            email: this.props.userInfo.email,
            isPending: this.props.userInfoPending,
            onSave: this.props.onUserInfoSave,
            isDialogVisible: show,
        };
        const newsletterTabProps = {
            subscribed: this.props.newsletter.subscribed,
        };
        const languageTabProps = {
            ...this.props.language,
            updateLanguage: this.props.updateLanguage,
        };

        return (
            <TabStructuredDialog
                show={show}
                onClose={onClose}
                title={this.renderMessage(TITLE_ID)}
                closeButtonLabel={this.renderMessage(CLOSE_BUTTON_LABEL_ID)}
                onTabChange={this.handleTabChange}
                tabIndex={tabIndex}
                tabs={this.props.showNewsLetter ? [
                    {
                        headline: this.renderMessage(PERSONAL_DATA_TAB_HEADLINE_ID),
                        content: <PersonalDataTab {...personalDataTabProps}/>,
                    }, {
                        headline: this.renderMessage(NEWSLETTER_TAB_HEADLINE_ID),
                        content: <NewsletterTab {...newsletterTabProps}/>,
                    },
                    {
                        headline: this.renderMessage(LANGUAGE_TAB_HEADLINE_ID),
                        content: <LanguageTab {...languageTabProps}/>,
                    },
                ] : [
                    {
                        headline: this.renderMessage(PERSONAL_DATA_TAB_HEADLINE_ID),
                        content: <PersonalDataTab {...personalDataTabProps}/>,
                    },
                    {
                        headline: this.renderMessage(LANGUAGE_TAB_HEADLINE_ID),
                        content: <LanguageTab {...languageTabProps}/>,
                    },
                ]
                }
            />
        );
    }
}

SettingsDialogContainer.propTypes = {
    show: PropTypes.bool,
    showNewsLetter: PropTypes.bool,
    onClose: PropTypes.func,
    fetchUserInfo: PropTypes.func,
    updateLanguage: PropTypes.func,
    onUserInfoSave: PropTypes.func,
    userInfo: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
    }),
    userInfoPending: PropTypes.bool,
    newsletter: PropTypes.shape({
        subscribed: PropTypes.bool,
        subscriptionDate: PropTypes.string,
    }),
    language: PropTypes.shape({
        locale: PropTypes.oneOf([...Object.keys(translations), '']).isRequired,
        isPending: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    backendUrl: PropTypes.string.isRequired,
};

SettingsDialogContainer.defaultProps = {
    show: false,
    showNewsLetter: false,
    onClose: noop,
    fetchUserInfo: noop,
    updateLanguage: noop,
    onUserInfoSave: noop,
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
    },
    userInfoPending: false,
    newsletter: {
        subscribed: false,
        subscriptionDate: '',
    },
    language: {
        isPending: false,
        error: '',
    },
    backendUrl: '',
};

function mapStateToProps(state) {
    const userInfo = selectUserInfo(state);
    const whiteList = pick(['firstName', 'lastName', 'email']);
    return {
        show: selectSettingsDialogVisibility(state),
        tabIndex: selectTabIndex(state),
        userInfo: whiteList(userInfo),
        newsletter: selectNewsletter(state),
        language: selectLanguage(state),
        userInfoPending: selectUserInfoPending(state),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    const backendUrl = ownProps.backendUrl || '';

    return {
        onClose: () => dispatch(hideSettingsDialogAction()),
        fetchUserInfo: () => dispatch(fetchUserInfoAction(backendUrl)),
        updateLanguage: (language) => dispatch(updateLanguageAction(language, backendUrl)),
        onUserInfoSave: (userObj) => dispatch(editUserAction(userObj, backendUrl)),
        changeTabAction: (tabIndex) => dispatch(changeTabAction(tabIndex)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialogContainer);
