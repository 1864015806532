/**
 * A list of allowed object properties to be sent to a logging system for error-analysis (currently Sentry.io)
 *
 */

const whiteProperties = [
    'userId',
    'email',
    'status',
    'fleetId',
    'registrationDate',
    'Id',
    'name',
    'roles',
    'userIds',
    'userGroupIds',
    'companyName',
    'vatIdNumber',
    'countryCode',
    'userIds',
    'error',
];

export default whiteProperties;
