
export const ACCESS_TOKEN_STORED = `ACCESS_TOKEN_STORED`;
export const accessTokenStored = token => ({
    payload: token,
    type: ACCESS_TOKEN_STORED,
});

export const ID_TOKEN_STORED = `ID_TOKEN_STORED`;
export const idTokenStored = token => ({
    payload: token,
    type: ID_TOKEN_STORED,
});

export const FETCH_USER_INFO_ACTION = `FETCH_USER_INFO_ACTION`;
export function fetchUserInfoAction(backendUrl) {
    return {
        type: FETCH_USER_INFO_ACTION,
        payload: backendUrl,
    };
}

export const FETCH_USER_INFO_SUCCESS_ACTION = `FETCH_USER_INFO_SUCCESS_ACTION`;
export function fetchUserInfoSuccessAction(userInfo) {
    return {
        type: FETCH_USER_INFO_SUCCESS_ACTION,
        payload: userInfo,
    };
}

export const FETCH_LANGUAGE_ACTION = `FETCH_LANGUAGE_ACTION`;
export function fetchLanguageAction(backendUrl) {
    return {
        type: FETCH_LANGUAGE_ACTION,
        payload: backendUrl,
    };
}

export const FETCH_LANGUAGE_SUCCESS_ACTION = `FETCH_LANGUAGE_SUCCESS_ACTION`;
export function fetchLanguageSuccessAction(language) {
    return {
        type: FETCH_LANGUAGE_SUCCESS_ACTION,
        payload: language,
    };
}

export const FETCH_LANGUAGE_FAILURE_ACTION = `FETCH_LANGUAGE_FAILURE_ACTION`;
export function fetchLanguageFailureAction() {
    return {
        type: FETCH_LANGUAGE_FAILURE_ACTION,
    };
}

export const UPDATE_LANGUAGE_ACTION = `UPDATE_LANGUAGE_ACTION`;
export function updateLanguageAction(language, backendUrl) {
    return {
        type: UPDATE_LANGUAGE_ACTION,
        payload: {
            language: language,
            backendUrl: backendUrl,
        },
    };
}

export const UPDATE_LANGUAGE_START_PENDING_ACTION = `UPDATE_LANGUAGE_START_PENDING_ACTION`;
export function updateLanguageStartPendingAction() {
    return {
        type: UPDATE_LANGUAGE_START_PENDING_ACTION,
    };
}

export const UPDATE_LANGUAGE_STOP_PENDING_ACTION = `UPDATE_LANGUAGE_STOP_PENDING_ACTION`;
export function updateLanguageStopPendingAction() {
    return {
        type: UPDATE_LANGUAGE_STOP_PENDING_ACTION,
    };
}

export const UPDATE_LANGUAGE_SUCCESS_ACTION = `UPDATE_LANGUAGE_SUCCESS_ACTION`;
export function updateLanguageSuccessAction(language) {
    return {
        payload: language,
        type: UPDATE_LANGUAGE_SUCCESS_ACTION,
    };
}

export const UPDATE_LANGUAGE_FAILURE_ACTION = `UPDATE_LANGUAGE_FAILURE_ACTION`;
export function updateLanguageFailureAction(errorCode) {
    return {
        type: UPDATE_LANGUAGE_FAILURE_ACTION,
        payload: errorCode,
    };
}

export const EDIT_USER_ACTION = `EDIT_USER_ACTION`;
export function editUserAction(userInfo, backendUrl) {
    return {
        type: EDIT_USER_ACTION,
        payload: {
            userInfo: userInfo,
            backendUrl: backendUrl,
        },
    };
}

export const EDIT_USER_SUCCESS_ACTION = `EDIT_USER_SUCCESS_ACTION`;
export function editUserSuccessAction(user) {
    return {
        type: EDIT_USER_SUCCESS_ACTION,
        payload: user,
    };
}

export const EDIT_USER_START_PENDING_ACTION = 'EDIT_USER_START_PENDING_ACTION';
export function editUserStartPendingAction() {
    return {
        type: EDIT_USER_START_PENDING_ACTION,
    };
}

export const EDIT_USER_STOP_PENDING_ACTION = 'EDIT_USER_STOP_PENDING_ACTION';
export function editUserStopPendingAction() {
    return {
        type: EDIT_USER_STOP_PENDING_ACTION,
    };
}
